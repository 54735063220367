@import 'variables';

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  box-shadow: 0px 2px 25px 0px rgba(0, 0, 0, 0.2);
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.midnightInner {
  overflow: hidden !important;
}

.wrapper {
  height: 100vh;
  width: 100vw;
}

.logo {
  color: #fff;
  font-family: $secondary-font;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  float: left;
  margin-left: 3%;
  min-height: 50px;

  span {
    color: #ec0f0f;
  }
  font-size: 48px;
  @media screen and (max-width: 576px) {
    font-size: 24px;
  }

  h1 {
    font-size: inherit;
    font-weight: 300;
    margin: 0;
  }
}
