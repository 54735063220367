@import "variables";
.top_bar {
  position: fixed;
  z-index: 1000;
  width: 100%;
  padding: 2%;
  padding-bottom: 0;
  @media screen and (max-width: 576px) {
  }

  .closeBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    z-index: 200;
    padding: 20px;
    // cursor: pointer;
    .arrow {
      width: 35px;
      background: #fff;
      display: block;
      height: 2px;
      &:before,
      &:after {
        content: "";
        display: block;
        position: relative;
        top: -6px;
        right: -26px;
        width: 12px;
        height: 2px;
        background: #fff;
        transform: rotate(45deg);
      }
      &:before {
        top: 4px;
        transform: rotate(-45deg);
      }
    }
  }
}
$menu-zindex: 1100;
$hamburger-color: $secondary-color;
$hamburger-width: 50px;
$hamburger-height: 50px;
$hamburger-zindex: $menu-zindex + 10;

.toggle-menu {
  background-color: transparent;
  border: none;
  // cursor: pointer;
  display: inline-block;
  float: right;
  height: $hamburger-height;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: $hamburger-width;
  z-index: $hamburger-zindex;

  span {
    background-color: $hamburger-color;
    content: "";
    display: block;
    height: 2px;
    left: calc(50% - 13px);
    position: absolute;
    top: calc(50% - 1px);
    transform-origin: 50% 50%;
    transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
      transform 0.2s linear;
    width: 26px;

    &:before,
    &:after {
      background-color: $hamburger-color;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      transform-origin: 50% 50%;
      transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
        transform 0.2s linear;
      width: 26px;
    }

    &:before {
      top: 7px;
    }

    &:after {
      top: -7px;
    }
    @media screen and(max-width:576px) {
      background-color: #fff;
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }

  &.active {
    span {
      background-color: transparent;
      transition: background 0.2s ease-out;

      &:before,
      &:after {
        transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
      }

      &:before {
        top: 0;
        transform: rotate3d(0, 0, 1, -45deg);
        background: #fff;
      }

      &:after {
        top: 0;
        transform: rotate3d(0, 0, 1, 45deg);
        background: #fff;
      }
    }
  }
}
