@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Catamaran|Karla|Khula|Muli|Quicksand|Tajawal");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display");
$primary-color: #ec0f0f;
$secondary-color: #182338;
$shadow-color: rgba(80, 80, 80, 0.2);
$white-blue: #e8ebf1;
$dark-gray: #3a3a3a;
$dark-blue: #213354;
$light-gray: #707070;
$grey-blue: #9ea7c5; //575e75
$mobile-text: #bdc3d6;
$mobile-text-sub: #b2b0c5;
$main-font: "Tajawal", sans-serif;
$secondary-font: "Playfair Display", serif;
